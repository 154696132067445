import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/node_modules/next/font/google/target.css?{\"path\":\"src/app/[lang]/layout.tsx\",\"import\":\"Roboto\",\"arguments\":[{\"weight\":[\"300\",\"400\",\"500\",\"700\"],\"display\":\"swap\",\"subsets\":[\"latin\",\"latin-ext\"]}],\"variableName\":\"roboto\"}");
;
import(/* webpackMode: "eager" */ "/opt/build/repo/webapps/tenant/src/app/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/opt/build/repo/webapps/tenant/src/components/providers/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/opt/build/repo/webapps/tenant/src/components/ui/toaster.tsx");
